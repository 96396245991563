<template>
  <div>
    <v-card class="ma-1 pa-1 h-100">
      <v-card-title class="pa-1">
        <span>{{ $t("blankTemplate.get") }}</span>
      </v-card-title>

      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        class="ma-1"
        style="border: 1px solid #aaa;"
        single-expand
        item-key="id"
        :server-items-length="server_items_length"
        item-text="template_name"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100, -1],
          itemsPerPageAllText:$t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        disable-sort
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
      >
        <template v-slot:item.id="{ item }">
          <span
            style="white-space:normal;display:block;cursor:pointer"
            @click="$router.push('/blank-templates/get-file/' + item.id)"
          >{{items.map(function(x) {return x.id; }).indexOf(item.id) + 1}}</span>
        </template>
        <template v-slot:item.blank_name="{ item }">
          <span
            @click="$router.push('/blank-templates/get-file/' + item.id)"
            style="white-space:normal;display:block;cursor:pointer"
            v-if="item"
          >{{ item.blank_name}}</span>
        </template>
        <template v-slot:item.description="{ item }">
          <span
            @click="$router.push('/blank-templates/get-file/' + item.id)"
            style="white-space:normal;display:block;cursor:pointer"
            v-if="item.description">{{ item.description }}</span>
        </template>
        <template v-slot:item.file_type="{ item }">
          <span v-if="item.file_type===0"><v-icon color="blue">mdi-file-word-outline</v-icon>Word</span>
          <span v-else><v-icon color="green">mdi-file-excel-outline</v-icon>Excel</span>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t('loadingText') }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  const axios = require("axios").default;
  import Swal from "sweetalert2";
  export default {
    data() {
      return {
        page: 1,
        from: 0,
        StaffDialog: false,
        server_items_length: -1,
        dataTableOptions: {page: 1, itemsPerPage: 50},
        dataTableValue: [],
        loading: false,
        items: [],
        formData: [],
        form: {},
      };
    },
    computed: {
      screenHeight() {
        return window.innerHeight - 170;
      },
      headers() {
        return [
          {text: "#", value: "id", align: "center", width: 30},
          {text: this.$t("blankTemplate.blank_name"), value: "blank_name"},
          {text: this.$t("documentTemplates.description"), value: "description"},
          {text: this.$t("blankTemplate.fileType"), value: "file_type"},
        ];
      }
    },
    methods: {
      updatePage($event) {
        this.getList();
      },
      updatePerPage($event) {
        this.getList();
      },
      getList() {
        this.loading = true;
        axios
          .post(this.$store.state.backend_url + "api/blank-templates", {
            pagination: this.dataTableOptions,
            search: this.search,
          })
          .then((response) => {
            this.items = response.data;
            this.server_items_length = response.data.total;
            this.from = response.data.from;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });

      },
    },
    mounted() {
      this.getList();
    }
  };
</script>
