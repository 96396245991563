var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1 h-100"},[_c('v-card-title',{staticClass:"pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("blankTemplate.get")))])]),_c('v-data-table',{staticClass:"ma-1",staticStyle:{"border":"1px solid #aaa"},attrs:{"dense":"","fixed-header":"","loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noDataText'),"height":_vm.screenHeight,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"single-expand":"","item-key":"id","server-items-length":_vm.server_items_length,"item-text":"template_name","footer-props":{
        itemsPerPageOptions: [20, 50, 100, -1],
        itemsPerPageAllText:_vm.$t('itemsPerPageAllText'),
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      },"options":_vm.dataTableOptions,"disable-pagination":true,"disable-sort":""},on:{"update:options":function($event){_vm.dataTableOptions=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"normal","display":"block","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/blank-templates/get-file/' + item.id)}}},[_vm._v(_vm._s(_vm.items.map(function(x) {return x.id; }).indexOf(item.id) + 1))])]}},{key:"item.blank_name",fn:function(ref){
      var item = ref.item;
return [(item)?_c('span',{staticStyle:{"white-space":"normal","display":"block","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/blank-templates/get-file/' + item.id)}}},[_vm._v(_vm._s(item.blank_name))]):_vm._e()]}},{key:"item.description",fn:function(ref){
      var item = ref.item;
return [(item.description)?_c('span',{staticStyle:{"white-space":"normal","display":"block","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/blank-templates/get-file/' + item.id)}}},[_vm._v(_vm._s(item.description))]):_vm._e()]}},{key:"item.file_type",fn:function(ref){
      var item = ref.item;
return [(item.file_type===0)?_c('span',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-file-word-outline")]),_vm._v("Word")],1):_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-excel-outline")]),_vm._v("Excel")],1)]}}])})],1),_c('v-dialog',{attrs:{"width":"300","hide-overlay":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('loadingText'))+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }